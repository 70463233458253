export default {
	setFeatures({ commit }, data) {
		commit('setFeatures', data);
	},

	setHistogram_byArea({ commit }, data) {
		commit('setHistogram_byArea', data);
	},

	setHistogram_byDatasourcetype({ commit }, data) {
		commit('setHistogram_byDatasourcetype', data);
	},

	setCounts_byArea({ commit }, data) {
		commit('setCounts_byArea', data);
	},

	setCounts_byDatasourcetype({ commit }, data) {
		commit('setCounts_byDatasourcetype', data);
	}
};
