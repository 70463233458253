export const NOTIFICATIONS_LAYER = 'notifications'; // TODO: Make this work for all organizations.

/* export function setNotificationsLayerConstants(value) {
	NOTIFICATIONS_LAYER = value;
	LAYER_NAMES_KPIS_AREA = [value];
	LAYER_NAMES_KPIS_DATASOURCETYPE = [value];
} */

export const AQIPOINTS_LAYER = 'aqipoints'; // TODO: Make this work for all organizations.

export const RASTER_LAYER_PROPERTIES = 'RasterLayer';

export const LAYER_NAMES_KPIS_AREA = [NOTIFICATIONS_LAYER];

export const LAYER_NAMES_KPIS_DATASOURCETYPE = [NOTIFICATIONS_LAYER];
