import { NOTIFICATIONS_LAYER } from '@/lib/constants/layers';

export const BASEMAP_ID = 'basemap-id';

// MAP VARIABLES
export const CENTER = [0, 0];
export const MIN_ZOOM = 1;
export const MAX_ZOOM = 19;
export const INITIAL_ZOOM = 1;

export const INITIAL_BOUNDS_KPIS = [-180, -90, 180, 90];

export const ZOOM_EXTENT_LAYER = NOTIFICATIONS_LAYER;
