import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

Vue.component('AppContainerHeader', () => import('@/layout/AppContainerHeader.vue'));
//Vue.component('appuserpanelelement', () => import('@/layout/AppUserPanelElement.vue'));
Vue.component('PmNotificationsGrid', () => import('@/components/pm_notifications/PmNotificationsGrid.vue'));
Vue.component('HistoricalNotificationsGrid', () => import('@/components/pm_notifications/HistoricalNotificationsGrid.vue'));

// GEN_REGISTER_COMPONENT_START
// GEN_REGISTER_COMPONENT_END

const puiRoutes = [
	/*{
		path: '/',
		redirect: 'map'
	},
	{
		path: 'home',
		redirect: 'map'
		//component: () => import('@Pui9Base/src/components/PuiWelcomePanel.vue')
	},*/
	{
		path: 'usersettings',
		component: () => import('@Pui9Admin/src/components/admin/puiuser/PuiUserSettingsForm.vue')
	},
	{
		path: 'puiaudit',
		component: () => import('@Pui9Admin/src/components/config/puiaudit/PuiAuditGrid.vue')
	},
	{
		path: 'session',
		component: () => import('@Pui9Admin/src/components/config/puisession/PuiSessionGrid.vue')
	},
	{
		path: 'puiuser',
		component: () => import('@Pui9Admin/src/components/admin/puiuser/PuiUserGrid.vue')
	},
	{
		path: '(.*/)?puiuser/:method/:pk',
		props: true,
		component: () => import('@Pui9Admin/src/components/admin/puiuser/PuiUserForm.vue')
	},
	{
		path: 'puiprofile',
		component: () => import('@Pui9Admin/src/components/admin/puiprofile/PuiProfileGrid.vue')
	},
	{
		path: '(.*/)?puiprofile/:method/:pk',
		props: true,
		component: () => import('@Pui9Admin/src/components/admin/puiprofile/PuiProfileForm.vue')
	},
	{
		path: 'puifunctionality',
		component: () => import('@Pui9Admin/src/components/admin/puifunctionality/PuiFunctionalityGrid.vue')
	},
	{
		path: 'puivariable',
		component: () => import('@Pui9Admin/src/components/config/puivariable/PuiVariableGrid.vue')
	},
	{
		path: '(.*/)?puivariable/:method/:pk',
		props: true,
		component: () => import('@Pui9Admin/src/components/config/puivariable/PuiVariableForm.vue')
	},
	{ path: 'puilanguage', component: () => import('@Pui9Admin/src/components/config/puilanguage/PuiLanguageGrid.vue') },
	{
		path: '(.*/)?puilanguage/:method/:pk',
		props: true,
		component: () => import('pui9-admin/src/components/config/puilanguage/PuiLanguageForm.vue')
	},
	{
		path: 'puiwidget',
		component: () => import('pui9-dashboard/src/components/puiwidget/PuiWidgetGrid.vue')
	},
	{
		path: '(.*/)?puiwidget/:method/:pk',
		props: true,
		component: () => import('pui9-dashboard/src/components/puiwidget/PuiWidgetForm.vue')
	},
	{
		path: 'puidashboard',
		component: () => import('pui9-dashboard/src/components/puidashboard/PuiDashboardGrid.vue')
	},
	{
		path: '(.*/)?puidashboard/:method/:pk',
		props: true,
		component: () => import('pui9-dashboard/src/components/puidashboard/PuiDashboardForm.vue')
	},
	{
		path: 'puiwidgetoverview',
		component: () => import('pui9-dashboard/src/components/PuiWidgetOverview.vue')
	},
	{
		path: 'puidashboardoverview',
		component: () => import('pui9-dashboard/src/components/PuiDashboardOverview.vue')
	}
];

const appRoutes = [
	// GEN_ROUTES_START
	// ---- OVERWRITING HOME ----
	{
		path: '/',
		redirect: 'home'
	},
	{
		path: 'home',
		component: () => import('@/views/home/index')
	},

	// ---- AGENTS ----
	{
		path: 'agents',
		component: () => import('@/views/agents/index')
	},

	// ---- LOG (INFO FROM NOTIFICATIONS) ----
	{
		path: 'log',
		component: () => import('@/views/log/index')
	},

	// ---- ORION ----
	{
		path: 'orion',
		component: () => import('@/views/orion/index')
	},

	// ---- MAP ----
	{
		path: 'map',
		component: () => import('@/views/map/MapPanel')
	},

	// ========= Organization (Platform Manager) ===================
	{
		path: 'pmorganization',
		component: () => import('@/components/pm_organization/PmOrganizationGrid')
	},
	{
		path: '(.*/)?pmorganization/:method/:pk',
		props: true,
		component: () => import('@/components/pm_organization/PmOrganizationForm')
	},

	// ========= Area Type (Platform Manager) ===================
	{
		path: 'pmareatype',
		component: () => import('@/components/pm_areatype/PmAreaTypeGrid')
	},
	{
		path: '(.*/)?pmareatype/:method/:pk',
		props: true,
		component: () => import('@/components/pm_areatype/PmAreaTypeForm')
	},

	// ========= Area (Platform Manager) ===================
	{
		path: 'pmarea',
		component: () => import('@/components/pm_area/PmAreaGrid')
	},
	{
		path: '(.*/)?pmarea/:method/:pk',
		props: true,
		component: () => import('@/components/pm_area/PmAreaForm')
	},

	// ========= Providers (Platform Manager) ===================
	{
		path: 'pmprovider',
		component: () => import('@/components/pm_provider/PmProviderGrid')
	},
	{
		path: '(.*/)?pmprovider/:method/:pk',
		props: true,
		component: () => import('@/components/pm_provider/PmProviderForm')
	},

	// ========= DataModel (Platform Manager) ===================
	{
		path: 'pmdatamodel',
		component: () => import('@/components/pm_datamodel/PmDataModelGrid')
	},
	{
		path: '(.*/)?pmdatamodel/:method/:pk',
		props: true,
		component: () => import('@/components/pm_datamodel/PmDataModelForm')
	},

	// ========= DataSourceType (Platform Manager) ===================
	{
		path: 'pmdatasourcetype',
		component: () => import('@/components/pm_datasourcetype/PmDataSourceTypeGrid')
	},
	{
		path: '(.*/)?pmdatasourcetype/:method/:pk',
		props: true,
		component: () => import('@/components/pm_datasourcetype/PmDataSourceTypeForm')
	},

	// ========= DataSourceInstance (Platform Manager) ===================
	{
		path: 'pmdatasourceinstance',
		component: () => import('@/components/pm_datasourceinstance/PmDataSourceInstanceGrid')
	},
	{
		path: '(.*/)?pmdatasourceinstance/:method/:pk',
		props: true,
		component: () => import('@/components/pm_datasourceinstance/PmDataSourceInstanceForm')
	},
	// ========= Time Unit (Platform Manager) ===================
	{
		path: 'pmtimeunit',
		component: () => import('@/components/pm_timeunit/PmTimeUnitGrid')
	},
	{
		path: '(.*/)?pmtimeunit/:method/:pk',
		props: true,
		component: () => import('@/components/pm_timeunit/PmTimeUnitForm')
	},
	// ========= Time Zone (Platform Manager) ===================
	{
		path: 'pmtimezone',
		component: () => import('@/components/pm_timezone/PmTimeZoneGrid')
	},
	{
		path: '(.*/)?pmtimezone/:method/:pk',
		props: true,
		component: () => import('@/components/pm_timezone/PmTimeZoneForm')
	},
	// ========= Spatial Table (Platform Manager) ===================
	{
		path: 'pmspatialtables',
		component: () => import('@/components/pm_spatialtables/PmSpatialTablesGrid')
	},
	{
		path: '(.*/)?pmspatialtables/:method/:pk',
		props: true,
		component: () => import('@/components/pm_spatialtables/PmSpatialTablesForm')
	},
	// ========= Unit (Platform Manager) ===================
	{
		path: 'pmunit',
		component: () => import('@/components/pm_unit/PmUnitGrid')
	},
	{
		path: '(.*/)?pmunit/:method/:pk',
		props: true,
		component: () => import('@/components/pm_unit/PmUnitForm')
	},
	// ========= Parameter (Platform Manager) ===================
	{
		path: 'pmparameter',
		component: () => import('@/components/pm_parameter/PmParameterGrid')
	},
	{
		path: '(.*/)?pmparameter/:method/:pk',
		props: true,
		component: () => import('@/components/pm_parameter/PmParameterForm')
	},
	// ========= Static Attributes (Platform Manager) ===================
	{
		path: 'pmstaticattributes',
		component: () => import('@/components/pm_staticattributes/PmStaticAttributesGrid')
	},
	{
		path: '(.*/)?pmstaticattributes/:method/:pk',
		props: true,
		component: () => import('@/components/pm_staticattributes/PmStaticAttributesForm')
	},
	// ========= bpaposidoniawebportcall (Use Case 3, Elasticsearch integration) ===================
	{
		path: 'bpaposidoniawebportcall',
		component: () => import('@/components/elasticsearch/UseCase3/bpaposidoniawebportcallGrid')
	},
	// ========= bpaposidoniawebtraffic (Use Case 2, Elasticsearch integration) ===================
	{
		path: 'bpaposidoniawebtraffic',
		component: () => import('@/components/elasticsearch/UseCase2/bpaposidoniawebtrafficGrid')
	},
	// ========= AQI Points (Platform Manager) ===================
	{
		path: 'pmaqipoints',
		component: () => import('@/components/pm_aqi_points/PmAqiPointsGrid')
	},
	{
		path: '(.*/)?pmaqipoints/:method/:pk',
		props: true,
		component: () => import('@/components/pm_aqi_points/PmAqiPointsForm')
	},
	// ========= External Raster Layers (Platform Manager) ===================
	{
		path: 'pmexternalrasterlayers',
		component: () => import('@/components/pm_externalraster_layers/PmExternalRasterLayersGrid')
	},
	{
		path: '(.*/)?pmexternalrasterlayers/:method/:pk',
		props: true,
		component: () => import('@/components/pm_externalraster_layers/PmExternalRasterLayersForm')
	},
	// ========= AQI Description (Platform Manager) ===================
	{
		path: 'pmaqidescription',
		component: () => import('@/components/pm_aqi_description/PmAqiDescriptionGrid')
	},
	{
		path: '(.*/)?pmaqidescription/:method/:pk',
		props: true,
		component: () => import('@/components/pm_aqi_description/PmAqiDescriptionForm')
	},
	// ========= Icons Type (Platform Manager) ===================
	{
		path: 'pmiconstype',
		component: () => import('@/components/pm_icons_type/PmIconsTypeGrid')
	},
	{
		path: '(.*/)?pmiconstype/:method/:pk',
		props: true,
		component: () => import('@/components/pm_icons_type/PmIconsTypeForm')
	},
	// ========= Icons (Platform Manager) ===================
	{
		path: 'pmicons',
		component: () => import('@/components/pm_icons/PmIconsGrid')
	},
	{
		path: '(.*/)?pmicons/:method/:pk',
		props: true,
		component: () => import('@/components/pm_icons/PmIconsForm')
	},

	// ========= Applications (Platform Manager) ===================
	{
		path: 'pmapplication',
		component: () => import('@/components/pm_application/PmApplicationGrid')
	},
	{
		path: '(.*/)?pmapplication/:method/:pk',
		props: true,
		component: () => import('@/components/pm_application/PmApplicationForm')
	},
	// ========= Visualizations (Platform Manager) ===================
	{
		path: 'pmvisualizations',
		component: () => import('@/components/pm_visualizations/PmVisualizationsGrid')
	},
	{
		path: '(.*/)?pmvisualizations/:method/:pk',
		props: true,
		component: () => import('@/components/pm_visualizations/PmVisualizationsForm')
	},

	// ========= Severity (Platform Manager) ===================
	{
		path: 'pmseverity',
		component: () => import('@/components/pm_severity/PmSeverityGrid')
	},
	{
		path: '(.*/)?pmseverity/:method/:pk',
		props: true,
		component: () => import('@/components/pm_severity/PmSeverityForm')
	},

	// ========= Status (Platform Manager) ===================
	{
		path: 'pmstatus',
		component: () => import('@/components/pm_status/PmStatusGrid')
	},
	{
		path: '(.*/)?pmstatus/:method/:pk',
		props: true,
		component: () => import('@/components/pm_status/PmStatusForm')
	},

	// ========= Rules (Platform Manager) ===================
	{
		path: 'pmrules',
		component: () => import('@/components/pm_rules/PmRulesGrid')
	},
	{
		path: '(.*/)?pmrules/:method/:pk',
		props: true,
		component: () => import('@/components/pm_rules/PmRulesForm')
	},

	// ========= Satellite Type (Platform Manager) ===================
	{
		path: 'pmsatellitetype',
		component: () => import('@/components/pm_satellite_type/PmSatelliteTypeGrid')
	},
	{
		path: '(.*/)?pmsatellitetype/:method/:pk',
		props: true,
		component: () => import('@/components/pm_satellite_type/PmSatelliteTypeForm')
	},
	// ========= Verticals (Platform Manager) ===================
	{
		path: 'pmverticals',
		component: () => import('@/components/pm_verticals/PmVerticalsGrid')
	},
	{
		path: '(.*/)?pmverticals/:method/:pk',
		props: true,
		component: () => import('@/components/pm_verticals/PmVerticalsForm')
	},
	// ========= Functionality (Platform Manager) ===================
	{
		path: 'pmfunctionality',
		component: () => import('@/components/pm_functionality/PmFunctionalityGrid')
	},
	{
		path: '(.*/)?pmfunctionality/:method/:pk',
		props: true,
		component: () => import('@/components/pm_functionality/PmFunctionalityForm')
	},
	// ========= Subscription (Platform Manager) ===================
	{
		path: 'pmsubscription',
		component: () => import('@/components/pm_subscription/PmSubscriptionGrid')
	},
	{
		path: '(.*/)?pmsubscription/:method/:pk',
		props: true,
		component: () => import('@/components/pm_subscription/PmSubscriptionForm')
	},
	// ========= Tiers (Platform Manager) ===================
	{
		path: 'aqtiers',
		component: () => import('@/components/aq_tiers/AqTiersGrid')
	},
	{
		path: '(.*/)?aqtiers/:method/:pk',
		props: true,
		component: () => import('@/components/aq_tiers/AqTiersForm')
	},
	// ========= Engine Types (Platform Manager) ===================
	{
		path: 'aqenginetypes',
		component: () => import('@/components/aq_enginetypes/AqEngineTypesGrid')
	},
	{
		path: '(.*/)?aqenginetypes/:method/:pk',
		props: true,
		component: () => import('@/components/aq_enginetypes/AqEngineTypesForm')
	},
	// ========= Engine Speeds (Platform Manager) ===================
	{
		path: 'aqenginespeeds',
		component: () => import('@/components/aq_enginespeeds/AqEngineSpeedsGrid')
	},
	{
		path: '(.*/)?aqenginespeeds/:method/:pk',
		props: true,
		component: () => import('@/components/aq_enginespeeds/AqEngineSpeedsForm')
	},
	// ========= Fuel Types (Platform Manager) ===================
	{
		path: 'aqfueltypes',
		component: () => import('@/components/aq_fueltypes/AqFuelTypesGrid')
	},
	{
		path: '(.*/)?aqfueltypes/:method/:pk',
		props: true,
		component: () => import('@/components/aq_fueltypes/AqFuelTypesForm')
	},
	// ========= Vessels (Platform Manager) ===================
	{
		path: 'aqvessels',
		component: () => import('@/components/aq_vessels/AqVesselsGrid')
	},
	// ========= Vessel Calls (Platform Manager) ===================
	{
		path: 'aqvesselcalls',
		component: () => import('@/components/aq_vesselcalls/AqVesselCallsGrid')
	},
	// ========= Vessel Engines Data (Platform Manager) ===================
	{
		path: 'aqvesselenginesdata',
		component: () => import('@/components/aq_vesselenginesdata/AqVesselEnginesDataGrid')
	},
	// ========= Vessel Call Consumptions (Platform Manager) ===================
	{
		path: 'aqvesselcallconsumptions',
		component: () => import('@/components/aq_vesselcallconsumptions/AqVesselCallConsumptionsGrid')
	},
	// ========= Vessel Call Docking (Platform Manager) ===================
	{
		path: 'aqvesselcalldocking',
		component: () => import('@/components/aq_vesselcalldocking/AqVesselCallDockingGrid')
	},
	// ========= Vessel Call Emissions Pollutant Version (Platform Manager) ===================
	{
		path: 'aqvesselcallemissionspollutantversion',
		component: () => import('@/components/aq_vesselcallemissionspollutantversion/AqVesselCallEmissionsPollutantVersionGrid')
	},
	// ========= Route Section (Platform Manager) ===================
	{
		path: 'aqroutesection',
		component: () => import('@/components/aq_routesection/AqRouteSectionGrid')
	},
	// ========= Route Section Location (Platform Manager) ===================
	{
		path: 'aqroutesectionlocation',
		component: () => import('@/components/aq_routesectionlocation/AqRouteSectionLocationGrid')
	},
	// ========= Route Section Emissions Pollutant (Platform Manager) ===================
	{
		path: 'aqroutesectionemissionspollutant',
		component: () => import('@/components/aq_routesectionemissionspollutant/AqRouteSectionEmissionsPollutantGrid')
	},
	// ========= Version Sfocfactor (Platform Manager) ===================
	{
		path: 'aqversionsfocfactor',
		component: () => import('@/components/aq_versionsfocfactor/AqVersionSfocfactorGrid')
	},
	// ========= Sfoc Factor (Platform Manager) ===================
	{
		path: 'aqvsfocfactor',
		component: () => import('@/components/aqv_sfocfactor/AqvSfocFactorGrid')
	},
	{
		path: '(.*/)?aqvsfocfactor/:method/:pk',
		props: true,
		component: () => import('@/components/aqv_sfocfactor/AqvSfocFactorForm')
	},

	// ========= Vessel Types (Platform Manager) ===================
	{
		path: 'aqvesseltypes',
		component: () => import('@/components/aq_vesseltypes/AqVesselTypesGrid')
	},
	{
		path: '(.*/)?aqvesseltypes/:method/:pk',
		props: true,
		component: () => import('@/components/aq_vesseltypes/AqVesselTypesForm')
	},
	// ========= Phases(Platform Manager) ===================
	{
		path: 'aqphases',
		component: () => import('@/components/aq_phases/AqPhasesGrid')
	},
	{
		path: '(.*/)?aqphases/:method/:pk',
		props: true,
		component: () => import('@/components/aq_phases/AqPhasesForm')
	},
	// ========= Pollutants (Platform Manager) ===================
	{
		path: 'aqpollutants',
		component: () => import('@/components/aq_pollutants/AqPollutantsGrid')
	},
	{
		path: '(.*/)?aqpollutants/:method/:pk',
		props: true,
		component: () => import('@/components/aq_pollutants/AqPollutantsForm')
	},
	// ========= Version Power Ratio (Platform Manager) ===================
	{
		path: 'aqversionpowerratio',
		component: () => import('@/components/aq_versionpowerratio/AqVersionPowerRatioGrid')
	},
	{
		path: '(.*/)?aqversionpowerratio/:method/:pk',
		props: true,
		component: () => import('@/components/aq_versionpowerratio/AqVersionPowerRatioForm')
	},
	// ========= Version Engine Power Ratio (Platform Manager) ===================
	{
		path: 'aqversionenginepowerratio',
		component: () => import('@/components/aq_versionenginepowerratio/AqVersionEnginePowerRatioGrid')
	},
	{
		path: '(.*/)?aqversionenginepowerratio/:method/:pk',
		props: true,
		component: () => import('@/components/aq_versionenginepowerratio/AqVersionEnginePowerRatioForm')
	},
	// ========= Version Load Factor (Platform Manager) ===================
	{
		path: 'aqversionloadfactor',
		component: () => import('@/components/aq_versionloadfactor/AqVersionLoadFactorGrid')
	},
	{
		path: '(.*/)?aqversionloadfactor/:method/:pk',
		props: true,
		component: () => import('@/components/aq_versionloadfactor/AqVersionLoadFactorForm')
	},
	// ========= Version Time (Platform Manager) ===================
	{
		path: 'aqversiontime',
		component: () => import('@/components/aq_versiontime/AqVersionTimeGrid')
	},
	{
		path: '(.*/)?aqversiontime/:method/:pk',
		props: true,
		component: () => import('@/components/aq_versiontime/AqVersionTimeForm')
	},
	// ========= Version Emision Factor (Platform Manager) ===================
	{
		path: 'aqversionemissionfactor',
		component: () => import('@/components/aq_versionemissionfactor/AqVersionEmissionFactorGrid')
	},
	{
		path: '(.*/)?aqversionemissionfactor/:method/:pk',
		props: true,
		component: () => import('@/components/aq_versionemissionfactor/AqVersionEmissionFactorForm')
	},
	// ========= Engine Power Ratio (Platform Manager) ===================
	{
		path: 'aqvenginepowerratio',
		component: () => import('@/components/aqv_enginepowerratio/AqvEnginePowerRatioGrid')
	},
	{
		path: '(.*/)?aqvenginepowerratio/:method/:pk',
		props: true,
		component: () => import('@/components/aqv_enginepowerratio/AqvEnginePowerRatioForm')
	},
	// ========= Vessel Load Factor (Platform Manager) ===================
	{
		path: 'aqvvesselloadfactor',
		component: () => import('@/components/aqv_vesselloadfactor/AqvVesselLoadFactorGrid')
	},
	{
		path: '(.*/)?aqvvesselloadfactor/:method/:pk',
		props: true,
		component: () => import('@/components/aqv_vesselloadfactor/AqvVesselLoadFactorForm')
	},
	// ========= Vessel Power Ratio (Platform Manager) ===================
	{
		path: 'aqvvesselpowerratio',
		component: () => import('@/components/aqv_vesselpowerratio/AqvVesselPowerRatioGrid')
	},
	{
		path: '(.*/)?aqvvesselpowerratio/:method/:pk',
		props: true,
		component: () => import('@/components/aqv_vesselpowerratio/AqvVesselPowerRatioForm')
	},
	// ========= Vessel Time (Platform Manager) ===================
	{
		path: 'aqvvesseltime',
		component: () => import('@/components/aqv_vesseltime/AqvVesselTimeGrid')
	},
	{
		path: '(.*/)?aqvvesseltime/:method/:pk',
		props: true,
		component: () => import('@/components/aqv_vesseltime/AqvVesselTimeForm')
	},
	// ========= Emission factor Time (Platform Manager) ===================
	{
		path: 'aqvemissionfactor',
		component: () => import('@/components/aqv_emissionfactor/AqvEmissionFactorGrid')
	},
	{
		path: '(.*/)?aqvemissionfactor/:method/:pk',
		props: true,
		component: () => import('@/components/aqv_emissionfactor/AqvEmissionFactorForm')
	},
	// ========= Notifications: Active (Platform Manager) ===================
	{
		path: 'pmnotifications',
		component: () => import('@/components/pm_notifications/PmNotificationsTabs')
	},
	// ========= Error Codes (Platform Manager) ===================
	{
		path: 'pmerrorcodes',
		component: () => import('@/components/pm_errorcodes/PmErrorCodesGrid')
	},
	{
		path: '(.*/)?pmerrorcodes/:method/:pk',
		props: true,
		component: () => import('@/components/pm_errorcodes/PmErrorCodesForm')
	},
	// ========= Schemas (Platform Manager) ===================
	{
		path: 'pmschema',
		component: () => import('@/components/pm_schema/PmSchemaGrid')
	},
	{
		path: '(.*/)?pmschema/:method/:pk',
		props: true,
		component: () => import('@/components/pm_schema/PmSchemaForm')
	},
	// ---- Dashboard ----
	{
		path: 'dashboard',
		component: () => import('@/views/dashboard/DashboardPanel')
	},
	// ---- Vessel Call Emissions ----
	{
		path: 'vesselcallemissions',
		component: () => import('@/views/vesselcall/VesselCallEmissions')
	},
	// ---- Automation Tool ----
	{
		path: 'automationtool',
		component: () => import('@/views/automationTool/ToolPanel')
	}
];

const puiNotFound = [{ path: '*', component: () => import('@Pui9Base/src/components/PuiNotFound') }];

function setAuthRequired(route) {
	return { ...route, meta: { ...route.meta, requiresAuth: true } };
}

const mergedRouter = {
	mode: 'history',
	hash: false,
	base: '/sp-client',
	routes: [
		{
			path: '/resetPassword',
			component: () => import('@Pui9Base/src/components/PuiUserResetPassword')
		},
		/* {
			path: '/login',
			component: () => import('@Pui9Base/src/components/PuiIntroLayout')
		}, */
		{
			path: '/login',
			component: () => import('@/components/login/eiffel_PuiIntroLayout')
		},
		{
			path: '/keycloaklogin',
			component: () => import('@Pui9Login/src/components/PuiKeycloakLogin')
		},
		{
			path: `/`,
			component: () => import('@Pui9Base/src/components/PuiBaseLayout'),
			children: puiRoutes.concat(appRoutes).concat(puiNotFound).map(setAuthRequired)
		}
	]
};

export default new VueRouter(mergedRouter);
