import puiEventsBus from './bus';
import i18n from './i18n/i18n';

export default async function beforeRun(store) {
	const session = store.getters.getSession;
	const userLogged = JSON.parse(window.localStorage.getItem(session.keepSessionAliveInfoKey));
	// Si no queremos login de pui, y además no hay un usuario en sesión...
	if (userLogged) {
		const pui9Lang = window.localStorage.getItem('pui9_lang');
		if (pui9Lang && pui9Lang !== userLogged.language) {
			userLogged.language = pui9Lang;
			window.localStorage.setItem(session.keepSessionAliveInfoKey, JSON.stringify(userLogged));
		}
		setLoginInfo(userLogged, store);
		puiEventsBus.$emit('onPuiLoginSignin', userLogged);
	}
}

function setLoginInfo(loginInfo, store) {
	//first commit the login info, the language will be set applying some logic
	store.commit('puiLoginSetInfo', loginInfo);
	//then we use the language saved, not the recieved
	const lang = store.getters.getUserLanguage;

	i18n.locale = lang;
	window.localStorage.setItem('pui9_lang', lang);
	store.commit('setUserLanguage', lang);
	store.commit('setHttpRequestHeaders', { authorization: loginInfo.jwt, acceptLanguage: lang });
}
